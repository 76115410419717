.img-1 {
    min-height: 52vh; /* Tinggi minimum 42% dari tinggi viewport */
    width: 100%; /* Lebar 100% */
    height: auto; /* Tetapkan tinggi ke nilai otomatis untuk menjaga proporsi */
    background-position: top center;
    background-repeat: no-repeat;
    z-index: 0;

    /*background-size: cover;*/
    /*filter: opacity(2) brightness(0.9);*/
}

.img-2 {
    background-image: url('https://cdn.discordapp.com/attachments/944807503844241408/1073144011927339028/chery_banner2-1.jpeg');
    min-height: 40vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    z-index: -10;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.section p {
    position: relative;
    top: 12px;
    font-size: 18px;
    width: 500px;
}

.section-1 {
    position: relative;
    text-align: right;
    z-index: 20 !important;
    top: 0vh;
    color: #000;
    font-size: 100%;
}

.section-1 h2 {
    font-weight: 500;
    border-radius: 3px;
    margin: 10px;
    background-color: rgba(243, 243, 243, 0.87);
}


.imgz {
    background-image: url('https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/production-studio.jpg');
    min-height: 100vh;
}

.imgz {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    filter: opacity(2) brightness(0.9);
    z-index: 10;
}

.sectionz {
    position: relative;
    z-index: 100 !important;
    top: 150px;
    color: #ffffff;
    font-size: 135%;
}